/* MOBILE (SMALL - LARGE) */
@media (max-width: 480px) {

    :root {
        --primary-green: #005046;
        --secondary-green: #056F62;
        --primary-red: #bb2f31e3;
        --secondary-red: #F9C4D7;
        --gray: #ddd;
        --white: #FFF;
        --black: #000;
    }


    header .second-header {
        display: none;
    }
    header .first-header {
        padding: 10px;
    }
    header .first-header .f-header-left {
        display: none;
    }
    header .first-header .f-header-right {
        display: flex;
        gap: 10px;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    header .first-header .f-header-right .header-icons p {
        font-size: 14px;
    }
    /* MOBILE HEADER */
    .mobile-header {
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        height: fit-content;
        padding: 20px 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        z-index: 2000;
        background-color: var(--primary-green);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    }
    .mobile-header img {
        width: 50px;
        object-fit: cover;
    }
    .mobile-header h4 {
        color: var(--white);
        font-size: 12px;
        font-family: "Work Sans";
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    /* HAMBURGER BUTTON */
    .hamburgerMenu {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
    }
    .hamburgerMenu .bar {
        width: 30px;
        height: 4px;
        border-radius: 5px;
        background-color: var(--white);
        margin: 2px 0;
        transition: transform 0.3s, opacity 0.3s;
    }
    .hamburgerMenu .bar.change:nth-child(1) {
        transform: translateY(5px) rotate(45deg);
    }
    .hamburgerMenu .bar.change:nth-child(2) {
        opacity: 0;
    }
    .hamburgerMenu .bar.change:nth-child(3) {
        transform: translateY(-10px) rotate(-45deg);
    }
    /* SIDE BAR MOBILE */
    .side-bar-mobile {
        display: none;
        background-color: var(--white);
        width: 80%;
        height: 100vh;
        padding: 20px;
        padding-top: 100px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        position: fixed;
        top: 0; 
        left: -380px; 
        transition: left 0.3s ease-in-out;
        z-index: 2200;
    }
    .side-bar-mobile.open {
        left: 0; 
    }
    .side-bar-mobile::-webkit-scrollbar {
        display: none;
    }
    .side-bar-mobile .sidebar-items {
        width: 100%;
        height: fit-content;
        position: absolute;
        top: 60px;
        left: 0;
    }
    .side-bar-mobile .sidebar-navlinks {
        width: 100%;
        height: fit-content;
        padding: 20px;
        list-style: none;
        text-align: center;
    }
    .side-bar-mobile .sidebar-navlinks ul {
        list-style-type: none;
        transition: left 0.3s ease-in-out;
    }
    .side-bar-mobile .sidebar-navlinks li {
        width: 100%;
        padding: 10px;
        text-align: left;
        font-size: 16px;
        font-weight: 700;
        font-family: 'Catamaran', sans-serif;
        text-decoration: none;
        text-align: left;
        color: var(--primary-color-light);
    }
    .side-bar-mobile .sidebar-navlinks button {
        background: none;
        border: none;
        color: var(--primary-dark);
        cursor: pointer;
        text-align: left;
        width: 100%;
        font-size: 16px;
        font-weight: 700;
        font-family: 'Catamaran', sans-serif;
        text-align: left;
        transition: left 0.3s ease-in-out;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .side-bar-mobile .sidebar-buttons {
        width: 100%;
        height: fit-content;
        padding: 20px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
    .side-bar-mobile .sidebar-buttons button {
        width: 100%;
        height: fit-content;
        padding: 15px 10px;
        border-radius: 80px;
        border: none;
        cursor: pointer;
        font-family: "Work Sans";
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: var(--white);
        background-color: var(--primary-green);
    }
    .side-bar-mobile .sidebar-buttons .book-appointment-button {
        padding: 15px 30px;
        width: 100%;
    }
    .side-bar-mobile .sidebar-buttons .student-portal-button {
        text-transform: uppercase;
    }
    /* FOOTER  */
    footer {
        padding: 20px;
    }
    footer .footer-top h6 {
        margin-bottom: 20px;
    }
    footer .footer-top {
        gap: 50px;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
    }
    footer .footer-top .f-top-one {
        width: 100%;
        gap: 10px;
    }
    footer .footer-top .f-top-one img {
        width: 100px;
        height: 100px;
    }
    footer .footer-top .f-top-one p {
        align-self: center;
    }
    footer .footer-top .f-top-three {
        width: 100%;
    }
    footer .footer-top .f-top-four {
        width: 100%;
    }
    footer .footer-top .f-top-four .subscribe-container input {
        width: 270px;
    }
    footer .footer-bottom {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 30px;
        text-align: center;
    }
    .page-caption {
        width: 100%;
        height: 150px;
        background-image: url('../Assets/Images/about-us-poster.png');
    }
    .departments-caption {
        width: 100%;
        height: 150px;
        background-image: url('../Assets/Images/departments-caption.png');
    }
    .schools-caption {
        width: 100%;
        height: 150px;
        background-image: url('../Assets/Images/school-caption.png');
    }
    .doctors-caption {
        width: 100%;
        height: 150px;
        background-image: url('../Assets/Images/school-caption.png');
    }
    .services-caption {
        width: 100%;
        height: 150px;
        background-image: url('../Assets/Images/services-caption.png');
    }
    .diseases-caption {
        width: 100%;
        height: 150px;
        background-image:  url('../Assets/Images/departments-caption.png');
    }
    .health-services-caption {
        width: 100%;
        height: 150px;
        background-image:  url('../Assets/Images/departments-caption.png');
    }
    .contact-caption {
        width: 100%;
        height: 150px;
        background-image: url('../Assets/Images/contact-us-caption.png');
    }

    .page-caption .page-caption-text, 
    .departments-caption .page-caption-text, 
    .schools-caption .page-caption-text, 
    .doctors-caption .page-caption-text, 
    .services-caption .page-caption-text, 
    .diseases-caption .page-caption-text,
    .health-services-caption .page-caption-text,
    .contact-caption .page-caption-text {
        top: 40px;
        left: 20px;
        font-size: 16px;
    }
    .page-caption .page-caption-text h5, 
    .departments-caption .page-caption-text h5, 
    .schools-caption .page-caption-text h5, 
    .doctors-caption .page-caption-text h5, 
    .services-caption .page-caption-text h5, 
    .diseases-caption .page-caption-text h5, 
    .health-services-caption .page-caption-text h5,
    .contact-caption .page-caption-text h5 {
        font-size: 24px;
    }
    /* GET IN TOUCH */
    .get-in-touch {
        padding: 20px;
    }
    .get-in-touch-caption h5 {
        font-size: 18px;
    }
    .get-in-touch-caption h6 {
        font-size: 32px;
    }
    .contact-container {
        margin-top: 30px;
        width: 100%;
        gap: 20px;
        flex-direction: column;
        padding: 0px;
    }
    .contact-container .contact-box {
        border-radius: 5px;
        padding: 50px 20px;
        flex-basis: 23%;
    }
    /* TESTIMONIALS */
    .testimonial-container {
        padding: 50px 20px;
    }
    .testimonial-container .testimonial-carousel {
        width: 100%;
        height: fit-content;
        margin-bottom: 20px;
    }
    .testimonial-container .testimonial-splide {
        width: 80%;
    }
    .testimonial-container .splide__arrow {
        margin: 0 -20px 0 -20px;
    }
    /* LANDING PAGE */
    .landing-page .hero-carousel-container {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0;
        position: relative;
        margin-bottom: 0;
    }
    .landing-page .hero-carousel {
        width: 100%;
        height: fit-content;
    }
    .landing-page .hero-carousel button {
        font-size: 14px;
        padding: 10px 20px;
        top: 65%;
        left: 20%;
        /* transform: translateY(40%); */
    }
    .landing-page .hero-carousel .hero-carousel-one {
        height: 230px;
    }
    .landing-page .hero-carousel .hero-carousel-two {
        height: 230px;
    }
    .landing-page .hero-carousel-caption {
        position: absolute;
        top: 25%;
        left: 10px;
        transform: translateY(25%);
    }
    .landing-page .hero-carousel-caption h5 {
        font-size: 14px;
        text-align: center;
    }
    .landing-page .hero-carousel-caption h1 {
        text-align: center;
        font-size: 22px;
    }
    .landing-page .carousel-nested-buttons {
       display: none;
    }
    /* ANNOUNCEMENT SECTION */
    .landing-page .announcement-container {
        padding: 30px 0px;
        padding-top: 150px;
    }
    .landing-page .announcement-container .announcement-caption h3 {
        font-size: 28px;
    }
    .landing-page .announcement-box {
        width: 75%;
        height: 100%;
    }
    .landing-page .announcement-box img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }
    .landing-page .announcement-box h5 {
        font-size: 18px;
        text-align: center;
    }
    .landing-page .announcement-box p {
        font-size: 16px;
        text-align: center;
    }
    .landing-page .announcement-container .announcement-carousel {
        width: 100%;
        height: fit-content;
        margin-bottom: 20px;
    }
    .landing-page .announcement-carousel .splide__arrow {
        font-size: 18px;
        background-color: transparent;
    }
    .landing-page .announcement-carousel .splide__pagination {
        text-align: center;
        position: absolute;
        bottom: -30px;
    }
    /* WELCOME SECTION */
    .landing-page .welcome-section {
        padding: 30px 20px;
    }
    .landing-page .welcome-section .welcome-section-caption {
        width: 100%;
    }
    .landing-page .welcome-section .welcome-section-caption h3 {
        font-size: 24px;
        text-align: center;
    }
    .landing-page .welcome-section .welcome-section-poster {
        width: 100%;
        height: 150px;
        position: relative;
        overflow: hidden; 
    }
    /* ABOUT US SECTION */
    .landing-page .about-us-section {
        padding: 20px;
        flex-direction: column;
    }
    .landing-page .about-us-section .about-us-caption h5 {
        font-size: 18px;
        text-align: center;
    }
    .landing-page .about-us-section .about-us-caption h3 {
        font-size: 32px;
    }
    .landing-page .about-us-section .toggle-display-container {
        flex-direction: column;
    }
    .landing-page .toggle-display-container .button-list {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .landing-page .toggle-display-container .button-list button {
        width: 50%;
        height: 100px;
    }
    .landing-page .toggle-display-container .button-list .learn-more-link {
        width: 100%;
        height: fit-content;
    }
    .landing-page .toggle-display-container .learn-more-link button {
        width: 100%;
        height: 50px;
    }
    .landing-page .toggle-display-container .display-section {
        padding: 0px;
    }
    .landing-page .display-section .about-us-display {
        flex-direction: column;
    }
    .landing-page .about-us-display .about-left {
        flex-direction: column;
        gap: 20px;
    }
    .landing-page .about-us-display .about-left h5 {
        width: 100%;
        text-align: center;
    }
    .landing-page .about-us-display .about-left ul li {
        width: 100%;
    }
    .landing-page .about-us-display .about-right {
        width: 100%;
        height: fit-content;
    }
    .landing-page .about-us-display .about-right img {
        width: 100%;
        height: 200px;
    }
    /* DEPARTMENTS AND FOCUS AREA */
    .landing-page .departments-f-areas-section {
        padding: 20px;
    }
    .landing-page .departments-f-areas-section .d-f-caption h5 {
        font-size: 18px;
    }
    .landing-page .departments-f-areas-section .d-f-caption h3 {
        font-size: 32px;
    }
    .landing-page .departments-f-areas-section .departments-container {
        width: 100%;
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
    .landing-page .departments-container .departments {
        padding: 20px;
    }
    /* BOOK APPOINTMENT */
    .landing-page .book-appointment {
        background-size: cover;
        background-position: top left;
        background-attachment: fixed;
        background-repeat: no-repeat;
        padding: 80px 20px;
        gap: 20px;
        flex-direction: column;
        background-repeat: repeat;
    }
    .landing-page .book-appointment .book-appointment-caption {
        width: 100%;
    }
    .landing-page .book-appointment .book-appointment-caption h3 {
        width: 100%;
        text-align: center;
        font-size: 32px;
    }
    .landing-page .book-appointment .book-appointment-caption p {
        font-size: 16px;
    }
    .landing-page .book-appointment .book-appointment-caption span {
        font-size: 20px;
    }
    .landing-page .book-appointment .book-appointment-caption .red-bold {
        font-size: 20px;
    }
    .landing-page .book-appointment .booking-form {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .landing-page .book-appointment .booking-form .patient-type-group {
        width: 100%;
    }
    .landing-page .book-appointment .booking-form .patient-type-group select {
        padding: 20px !important;
    }
    .landing-page .book-appointment .booking-form .phone-number-group,
    .landing-page .book-appointment .booking-form .name-group, 
    .landing-page .book-appointment .booking-form .email-group {
        width: 100%;
    }
    .landing-page .book-appointment .booking-form .appointment-date-group, 
    .landing-page .book-appointment .booking-form .time-group {
        width: 100%;
    }
    .landing-page .book-appointment .booking-form .appointment-date-group input, 
    .landing-page .book-appointment .booking-form .time-group input {
        width: 100% !important;
        padding: 20px !important;
    }
    .landing-page .book-appointment .booking-form .message-group {
        width: 100%;
    }
    .landing-page .book-appointment .booking-form .upload-file-group {
        flex-direction: column;
    }
    .landing-page .book-appointment .booking-form .upload-file-group p {
        width: 100%;
    }
    .landing-page .book-appointment .booking-form .upload-file-group input {
        width: 100%;
    }
    .landing-page .booking-form .file-input-wrapper {
        width: 100%;
    }

    /* OUR PROFESSIONALS */
    .professionals-container {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        padding: 20px;
        margin-bottom: 30px;
    }  
    .professionals-container .our-professionals-caption h5 {
        font-size: 14px;
    }
    .professionals-container .our-professionals-caption h3 {
        font-size: 24px;
    }
    .doctors-carousel, 
    .teams-carousel {
        width: 90%;  
    }
    .professionals-box {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .professionals-box .professionals-image {
        width: 100%;
        height: fit-content;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
    }
    .professionals-box .professionals-image img {
        height: 300px;
    }
    .professionals-box .professionals-caption {
        padding: 20px 10px;
    }
    
   
    /* PLACES WE CAN BE FOUND */
    .landing-page .locations-section {
        padding: 50px 20px;
    }
    .landing-page .locations-section .locations-container a {
        width: 100%;
    }
    .landing-page .locations-section .locations-container .locations-box {
        width: 100%;
    }

    /* ABOUT US PAGE */
    .about-oauthc .about-poster-text {
        padding: 50px 20px;
        display: flex;
        flex-direction: column-reverse;
        gap: 20px;
    }
    .oauthc-history {
        padding: 20px;
    }
    .about-oauthc .about-poster-text .about-oauthc-poster {
        width: 100%;
        height: 400px;
    }
    .about-oauthc .about-poster-text .about-oauthc-text {
        justify-content: center;
        align-items: center;
    }
    .about-oauthc .about-poster-text .about-oauthc-text h5 {
        font-size: 18px;
        text-align: center;
    }
    .about-oauthc .about-poster-text .about-oauthc-text h4 {
        font-size: 25px;
        text-align: center;
    }
    .about-oauthc .vision-mission-goal {
        padding: 50px 20px;
        flex-direction: column;
    }
   
    /* PEOPLE CULTURE  */
    .about-oauthc .people-culture {
        padding: 20px;
        display: flex;
        flex-direction: column;
    }
    .about-oauthc .people-culture .people-culture-text {
        margin-top: 20px;
        width: 100%;
        flex-direction: column;
    }
    .about-oauthc .people-culture .people-culture-text h5 {
        font-size: 18px;
        text-align: center;
    }
    .about-oauthc .people-culture .people-culture-text h4 {
        font-size: 30px;
        text-align: center;
    }
    .about-oauthc .people-culture .people-culture-text p {
        font-size: 16px;
    }
    .about-oauthc .people-culture .people-culture-poster {
        top: auto;
        right: auto;
        margin: auto;
        width: 90%;
        height: 400px;
        background-image: url('../Assets/Images/people-culture-poster.png');
        background-size: cover;
        background-position: top center;
        position: static;
    }
    .our-values-container {
        margin-top: 50px;
        padding: 20px;
    }
    .our-values-container .our-values-caption h5 {
        font-size: 18px;
    }
    .our-values-container .our-values-caption h6 {
        font-size: 32px;
    }
    .our-values-container .our-values-section {
        width: 100%;
        height: fit-content;
        display: grid;
        gap: 20px;
        grid-template-columns: 1fr; 
    }
    .our-values-section .our-values img {
        width: 50px;
        height: 50px;
        object-fit: cover;
    }
    .our-values-section .our-values h5 {
        font-size: 21px;
    }
    .our-values-section .our-values p {
        font-size: 16px;
    }


    /* DEPARTMENTS AND CENTERS */
    .departments-centers-section {
        padding: 20px;
        padding-top: 30px;
        gap: 50px;
    }
    .departments-centers-section .departments-centers-text p {
        font-size: 16px;
    }
    .departments-centers-section .departments-log .departments-container {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 70px;
    }
    .departments-centers-section .departments-log .department {
        height: 300px;
        padding: 30px;
        border-radius: 10px;
        position: relative;
    }
    .departments-centers-section .departments-log .department img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }
    .departments-centers-section .departments-log .department .department-caption {
        position: absolute;
        bottom: 0px;
        left: 5%;
        transform: translateY(40%); 
        width: 90%;
        font-size: 16px;
    }
    .departments-centers-section .departments-log .departments-pagination {
        margin-top: 50px;
        width: 100%;
        padding: 0px;
        gap: 5px;
        justify-content: center;
    }
    .departments-centers-section .departments-log .departments-pagination button {
        padding: 10px 15px;
        font-size: 16px;
    }
    .appointment-poster {
        margin-top: 200px;
        padding: 20px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    .appointment-poster .appointment-poster-pic {
        width: 200px;
        height: 200px;
        left: 20px;
        background-image: url('../Assets/Images/Group\ 1.png');
    }
    .appointment-poster .appointment-poster-caption {
        gap: 10px;
    }
    .appointment-poster .appointment-poster-caption h5 {
        font-size: 20px;
    }
    .appointment-poster .appointment-poster-caption h6 {
        font-size: 18px;
    }
    .appointment-poster .appointment-poster-caption button {
        padding: 15px 40px;
        font-size: 14px;
    }

    /* DEPARTMENTS AND CENTERS */
    .departments-centers-section {
        padding: 20px;
        gap: 30px;
        flex-direction: column;
    }
    .departments-centers-section .departments-centers-text p {
        font-size: 16px;
    }
    .departments-centers-section .departments-log .departments-container {
        display: grid;
        grid-template-columns: 1fr; 
        gap: 60px 50px;
    }
    .departments-centers-section .departments-log .department {
        height: 300px;
    }
    .departments-centers-section .departments-log .department .department-caption {
        left: 5%;
        font-size: 16px;
    }
    .departments-centers-section .departments-log .departments-pagination {
        margin-top: 50px;
        padding: 0px;
        gap: 10px;
    }
    .departments-centers-section .departments-log .departments-pagination button {
        padding: 10px 12px;
        font-size: 16px;
    }
    .appointment-poster {
        margin-top: 0px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: unset;
    }
    .appointment-poster .appointment-poster-pic {
        width: 100%;
        height: 300px;
        position: unset;
        top: auto;
        left: auto;
    }
    .appointment-poster .appointment-poster-caption h5 {
        font-size: 24px;
    }
    .appointment-poster .appointment-poster-caption h6 {
        font-size: 20px;
    }
    .appointment-poster .appointment-poster-caption button {
        padding: 15px 30px;
        font-size: 16px;
    }
    
    /* DEPARTMENT DETAILS */
    .department-details {
        padding: 0;
    }
    .department-details .department-overview-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        padding: 20px;
    }
    .department-details .department-overview-container .departments-poster {
        width: 100%;
        height: fit-content;
    }
    .department-details .department-overview-container .departments-overview {
        width: 100%;
    }
    .department-details .department-overview-container .departments-overview h5 {
        font-size: 24px;
        margin-bottom: 10px ;
    }
    .department-details .department-overview-container .departments-overview h6 {
        font-size: 16px;
    }
    .department-details .department-overview-container .departments-overview p {
        font-size: 16px;
    } 
    .department-details .departments-facilities {
        width: 100%;
        gap: 20px;
        padding: 30px 20px;
    }
    .department-details .departments-facilities h5 {
        font-size: 24px;
    }
    .department-details .departments-facilities p {
        font-size: 16px;
    }
    .department-details .departments-facilities li {
        font-size: 16px;
    }
    .department-details .departments-contacts {
        padding: 20px;
    }

    /* DOCTORS LOG */
    .find-doctor {
        padding: 20px;
    }
    .find-doctor .search-filters {
        display: none;
    }
    .find-doctor .search-filters select, 
    .find-doctor .search-filters input {
        padding: 10px;
        border-radius: 5px;
        border: solid 1px var(--black);
        outline: solid 1px var(--black);
        font-family: "Work Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
    }
    .find-doctor .doctors-log .doctors-container {
        width: 100%;
        grid-template-columns: 1fr;
    }
    .find-doctor .doctors-log .doctor-card {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
    }
    .find-doctor .doctor-card .doctor-card-caption {
        gap: 10px;
    }
    .find-doctor .doctor-card .doctor-card-caption img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        object-fit: cover;
    }
    .find-doctor .doctor-card .doctor-card-caption h4 {
        font-size: 16px;
    }
    .find-doctor .doctor-card .doctor-card-caption h6 {
        font-size: 14px;
    }
    .find-doctor .doctor-card p {
        font-size: 14px;
    }
    .find-doctor .doctor-card-buttons .doctor-profile-button {
        font-size: 12px;
        padding: 5px;
    }
    .find-doctor .doctor-card-buttons .doctor-appointment-button {
        font-size: 14px;
        padding: 5px;
    }
    .find-doctor .doctors-pagination {
        margin-top: 50px;
        width: 100%;
        padding: 0px;
        gap: 5px;
        justify-content: center;
    }
    .doctors-pagination button {
        font-size: 16px;
        padding: 10px 15px;
    }
    /* DOCTOR PROFILE */
    .back-button {
        padding: 20px;
    }
    .doctor-details .doctor-overview-container {
        padding: 20px;
        display: flex;
        gap: 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .doctor-details .doctor-overview-container .doctor-poster {
        width: 90%;
        height: 400px;
    }
    .doctor-details .doctor-overview {
        width: 100%;
        margin-top: 20px;
    }
    .doctor-details .doctor-overview h3 {
        font-size: 24px;
    }
    .doctor-details .doctor-overview h5 {
        font-size: 16px;
    }
    .doctor-details .doctor-overview .doctor-icons-cont {
        gap: 10px; 
    }
    .doctor-details .doctor-overview .doctor-icons p {
        font-size: 16px;
    }
    .doctor-details .doctor-overview h4 {
        font-size: 16px;
    }
    .doctor-details .doctor-overview p {
        font-size: 16px;
    }

    /* OAUTHC LOCATION */
    .oauthc-location {
        padding: 20px;
    }
    .oauthc-location .oauthc-location-caption p {
        font-size: 16px;
    }
    .oauthc-location .hospital-units {
        width: 100%;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 20px;
    }
    .oauthc-location .hospital-units .hospital-units-poster {
        width: 100%;
        height: 150px;
    }
    .oauthc-location .hospital-units .hospital-units-caption {
        padding: 10px;
        width: 100%;
    }
    .oauthc-location .hospital-units .hospital-units-caption h4 {
        font-size: 24px;
    }
    .oauthc-location .hospital-units .hospital-units-caption > P {
        font-size: 16px;
        margin-bottom: 10px;
    }
    .oauthc-location .hospital-units .hospital-units-caption .unit-icons p {
        font-size: 16px;
    }
    .oauthc-location .hospital-units .hospital-units-caption .unit-links {
        font-size: 16px;
        margin-left: 20px;
        margin-right: 10px;
    }
    .oauthc-location .hospital-units .hospital-units-caption .list-icon {
        font-size: 16px;
    }
  
    /* HEALTH SERVICES */
    .health-services-section .health-service-text span {
        font-size: 18px;
    }
    .health-services-section .health-service-text p {
        font-size: 16px;
    }
    .health-services-section .services-log {
        padding: 20px;
        gap: 30px;
    }
    .health-services-section .services-log .services-container {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        gap: 70px 50px;
    }
    .health-services-section .services-log .service {
        height: 300px;
        padding: 30px;
    }
    .health-services-section .services-log .service .service-caption {
        font-size: 16px;
        padding: 10px;
    }
    .health-services-section .services-log .services-pagination {
        margin-top: 50px;
        width: 100%;
        padding: 0px;
        gap: 5px;
        justify-content: center;
    }
    .health-services-section .services-log .services-pagination button {
        padding: 10px 15px;
        font-size: 16px;
    }

    /* DISEASES AND SYMPTOMS */
    .diseases-symptoms-section {
        padding: 20px;
    }
    .diseases-symptoms-section .diseases-symptoms-nav {
        padding: 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .diseases-symptoms-nav .diseases-nav-left h4 {
        font-size: 24px;
    }
    .diseases-symptoms-nav .diseases-nav-left p {
        font-size: 14px;
        margin-bottom: 20px;
    }
    .diseases-symptoms-nav .diseases-nav-search-container h5 {
        font-size: 16px;
    }
    .diseases-symptoms-nav .diseases-nav-search-container .disease-search {
        min-width: 100%;
        padding: 15px;
        border-radius: 30px;
        font-size: 16px;
    }
    .diseases-symptoms-nav .diseases-nav-right {
        width: 100%;
        margin-top: 20px;
    }
    .diseases-symptoms-nav .diseases-nav-right h5 {
        font-size: 16px;
    }
    .diseases-symptoms-nav .diseases-nav-right .alphabet-buttons button {
        font-size: 24px;
    }
    /* DISEASES AND SYMPTOMS DISPLAY */
    .diseases-symptoms-section .diseases-display-container {
        width: 100%;
        margin-bottom: 50px;
    }
    .diseases-symptoms-section .diseases-display-container h6 {
        align-self: flex-start;
        justify-self: flex-start;
        width: 100%; 
        text-align: left;
        font-family: "Work Sans";
        font-size: 14px;
    }
    .diseases-symptoms-section .diseases-symptoms-display {
        width: 100%;
        height: fit-content;
    }
    .diseases-symptoms-section .diseases-symptoms-display ul {
        width: 100%;
        height: fit-content;
        padding: 10px 50px; 
        display: flex;
        gap: 20px;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        list-style-type: none;
    }

    /* TEST AND PROCEDURES */
    .tests-procedures-details {
        padding: 20px;
    }
    .tests-procedures-details h4 {
        font-size: 24px;
    }
    .tests-procedures-details h5 {
        font-size: 20px;
    }
    .tests-procedures-details h6 {
        font-size: 16px;
    }
    .tests-procedures-details p {
        font-size: 16px;
    }
  
 
    /* CONTACT US */
    .contact-us-map {
        width: 100%;
        height: fit-content;
        padding: 20px;
    }
    .contact-us-map .map {
        width: 100%;
        height: 350px; 
    }
    .contact-us-form {
        padding: 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
    .contact-get-in-touch {
        width: 100%; 
        height: fit-content;
    }
    .contact-get-in-touch .get-in-touch {
        padding: 0;
    }
    .contact-form {
        width: 100%;
        height: fit-content;
    }
    .contact-form form {
        margin-top: 10px;
    }
    .contact-form form input, 
    .contact-form form textarea {
        padding: 20px;
        font-size: 14px;
    }
    .contact-form form .contact-name-input {
        width: 100%;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
    }
    .contact-form form .contact-email-input {
        width: 100%;
        border-top-right-radius: 0px;
    }
    .contact-form form .contact-message-input {
        min-height: 150px;
        padding: 10px;
    }
    .contact-form form .contact-submit-button {
        padding: 20px;
        font-size: 16px;
    }
    
    /* TERMS OF CONDITION
    PRIVACY POLICY */
    .privacy-policy-page {
        padding: 50px 20px;
    }
    .privacy-policy-page h1 {
        font-size: 24px;
    }
    .privacy-policy-page h5 {
        font-size: 18px;
    }
    .privacy-policy-page p {
        font-size: 16px;
        margin-bottom: 20px;
    }
    .privacy-policy-page ul li {
        font-size: 16px;
    }
    .privacy-policy-page ol li {
        font-size: 16px;
        margin-left: 30px; 
    }

    /* STUDENT PORTAL */
    .student-portal {
        width: 100vw;
        height: fit-content;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 2100; 
    }
    .student-portal .student-portal-poster {
        width: 100%;
        height: 300px;
        background-image: url(../Assets/Images/student-portal.png);
        background-position: top center;
        background-size: cover;
    }
    .student-portal .student-portal-text {
        width: 100%;
        padding: 10px;
    }
    .student-portal .student-portal-text h4 {
        font-size: 14px;
    }
    .student-portal .portal-content {
        width: 100%;
        margin-top: 10px;
    }
    .student-portal .portal-content .coming-soon {
        font-size: 14px;
    }
    .student-portal .portal-content h1 {
        margin-top: 20px;
        font-size: 24px;
    }
    .student-portal .portal-content p {
        font-size: 16px;
    }
    .student-portal .student-portal-form {
        width: 100%;
        gap: 10px;
        justify-content: center;
    }
    .student-portal .student-portal-form input {
        width: 100%;
        padding: 10px;
        font-size: 16px;
    }
    .student-portal .student-portal-form button {
        width: 150px;
        padding: 10px;
        font-size: 14px;
    }
    /* RESEARCH AND ETHICS */
    .research-ethics {
        width: 100%;
        padding: 20px;
    }
    .research-ethics h1 {
        font-size: 20px;
    }
    .research-ethics .research-carousel {
        width: 100%;
        height: fit-content;
        position: relative;
        margin-bottom: 50px;
    }
    .research-carousel .research-button {
        padding: 10px;
        width: 90%; 
        top: 0;
        right: 0;
    }
    .research-carousel .research-button h4 {
        font-size: 20px;
    }
    .research-carousel .research-carousel-one {
        height: 300px;
    }
    .research-carousel .research-carousel-two {
        height: 300px;
    }
    .research-carousel .research-carousel-three {
        height: 300px;
    }
    .research-ethics .research-application-rules {
        width: 100%;
        height: fit-content;
        padding: 0px;
    }
    .research-ethics .research-application-rules h3 {
        text-align: left;
        font-size: 18px;
        margin-bottom: 10px;
    }
    .research-ethics .research-application-rules h6 {
        font-size: 16px;
        margin-bottom: 10px;
    }
    .research-ethics .research-application-rules ol {
        margin-left: 20px;
        text-align: left;
    }
    .research-ethics .research-application-rules li {
        margin-bottom: 10px;
    }
    .research-ethics .research-form {
        padding: 10px; 
    }
    .research-ethics form .name-email-inputs {
        flex-wrap: wrap;
        gap: 10px;
    } 
    .research-ethics form .name-email-inputs input {
        min-width: 100%;
        padding: 20px;
        border-radius: 4px;
    } 
    .research-ethics form ol {
        margin-left: 30px;
        text-align: left;
        font-size: 16px;
    }
    .research-ethics form ol span {
        margin-right: 10px; 
        width: 100%;
    }
    .research-ethics .research-submit-button { 
        margin-top: 20px;
    }
  
}